import { React, useEffect, useState } from 'react';
import Buttons from "../../../../components/ui/button/Buttons";
import formatDate from "../../../../lib/formatDate";
import { saveAs } from 'file-saver';
// Import API services
import { useLazyGetPDFQuery } from "../../../../app/services/Common/CommonApi";
import { useGetMembersFinancialInformationByIdMutation } from "../../../../app/services/membershipFinancialService/MembershipFinancialServices";
import ToastService from '../../../../services/toastService/ToastService';


const FinancialRecord = (props) => {
    const [getPDF, { isSuccess: isSuccessGetPDF, isLoading: isLoadingGetPDF, data: getPDFData }] = useLazyGetPDFQuery();

    // API for get financial details
    const [getFinanceInfo,
        {
            data: financeInfoResponse,
            isSuccess: isFinanceInfoSuccess,
            isLoading: isFinanceInfoLoading
        }
    ] = useGetMembersFinancialInformationByIdMutation();

    // Method to get financial information
    const getFinancialRecordDetails = () => {
        let financeInfoRequest = {}
        financeInfoRequest.corporateMemberId = props.isCorporateMember ? props.corporateMemberId : 0;
        financeInfoRequest.individualMemberId = props.isIndividualMember ? props.individualMemberId : 0;
        financeInfoRequest.academicMemberId = props.isAcademicMember ? props.academicMemberId : 0;
        getFinanceInfo(financeInfoRequest);
    }

    useEffect(() => {
        getFinancialRecordDetails();
    }, []);

    // get finance info response
    useEffect(() => {
        if (!isFinanceInfoLoading && isFinanceInfoSuccess && financeInfoResponse) {
        }
    }, [isFinanceInfoLoading, isFinanceInfoSuccess, financeInfoResponse]);

    useEffect(() => {
        if (!isLoadingGetPDF && isSuccessGetPDF && getPDFData) {
            if (getPDFData) {
                saveAs(getPDFData?.fileData, getPDFData?.fileName);
            } else {
                ToastService.error("Something Went Wrong")
            }
        }
    }, [isLoadingGetPDF, isSuccessGetPDF, getPDFData]);

    const onInvoiceReceiptFileDownload = async (flag, name) => {
        const fileName = name.split('\\').pop();
        let request = {
            type: flag,
            fileName: fileName,
        }
        getPDF(request);
    }

    return (
        <>
            {financeInfoResponse?.map((item, key) => (
                <div className="col-lg-12 position-relative" key={key}>
                    <div className={`${item.paymentStatus === "Success" ? 'paid-lable' : 'pending-lable'}`}>
                        <span className="fs-6">
                            {
                                item.paymentStatus === "Success" ?
                                    'Paid'
                                    :
                                    'Pending'
                            }
                        </span>
                    </div>
                    <div className="row pt-5">
                        <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 " >
                            <div>
                                <label>
                                    Invoice Number :
                                </label>
                                <span> {item.membershipPaymentInvoiceNo}</span>
                            </div>
                            <div>
                                <label>
                                    Date :
                                </label>
                                <span> {formatDate(item.paymentDate, 'DD/MM/YYYY')}</span>
                            </div>
                            <div>
                                <label>
                                    Payment Status :
                                </label>
                                <span> {item.paymentStatus ? item.paymentStatus : ""}</span>
                            </div>
                            <div>
                                <label>
                                    Payment Method :
                                </label>
                                <span>{item.paymentVia}</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12  d-flex justify-content-end ">
                            <div className="mr-5">
                                <div>
                                    <label>
                                        Promotion Code :
                                    </label>
                                    <span> N.A</span>
                                </div>
                                <div>
                                    <label>
                                        Amount(Billable) :
                                    </label>
                                    <span>S${parseFloat(item.totalAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                </div>
                                <div>
                                    <label>
                                        Amount(Paid) :
                                    </label>
                                    <span>S${item.paymentStatus === "Success" ? parseFloat(item.totalAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : parseFloat(0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                </div>
                            </div>
                            <div className="text-end">
                                <Buttons
                                    buttonText="View Invoice"
                                    buttonTypeClassName={`outline-btn m-2 ${item.invoicePath === null ? "d-none" : ""}`}
                                    onClick={() => onInvoiceReceiptFileDownload("MembershipInvoice", item?.invoicePath)}
                                />
                                <Buttons
                                    buttonText="View Receipt"
                                    buttonTypeClassName={`outline-btn m-2 ${item.receiptPath === null ? "d-none" : ""}`}
                                    onClick={() => onInvoiceReceiptFileDownload("MembershipPaymentReceipt", item?.receiptPath)}
                                />
                            </div>
                            {/* <div className="text-end">
                            {
                                    //item.paymentStatus === "Pending" &&  item.paymentVia === "Credit Card/PayPal"  ?
                                    !item.invoicePath ?
                                        null
                                        :
                                <Buttons
                                    buttonText="View Invoice"
                                    buttonTypeClassName={`outline-btn m-2`}
                                    onClick={() => onInvoiceReceiptFileDownload("MembershipInvoice", item?.invoicePath)}
                                />
                            }
                                {
                                  //  item.paymentStatus === "Pending" && (item.paymentVia === "Credit Card/PayPal" || item.paymentVia === "Bank Transfer" || item.paymentVia === "PayNow") ?
                                      !item.receiptPath ?
                                        null
                                        :
                                        <Buttons
                                            buttonText="View Receipt"
                                            buttonTypeClassName={`outline-btn m-2`}
                                            onClick={() => onInvoiceReceiptFileDownload("MembershipPaymentReceipt", item?.receiptPath)}
                                        />
                                }

                            </div> */}
                        </div>
                    </div>
                </div >
            ))}
        </>
    )
}
export default FinancialRecord;