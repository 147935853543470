import React, { useEffect, useRef, useState } from 'react';
import { GridConfig } from './data/ViewEditAdminAccountForm.data.js';
import { useNavigate } from 'react-router-dom';
import MolGrid from '../../components/Grid/MolGrid.js';
import Label from '../../components/ui/label/Label.js';
import Input from '../../components/ui/inputs/input/Input.js';
import DataLoader from '../../components/ui/dataLoader/DataLoader';
// Import API services
import {
    useGetAdminDetailListMutation,
    useDeleteAdminDetailsByIdMutation
} from '../../app/services/AccountManagement/AccountManagementApi.js';
import SwalAlert from '../../services/swalService/SwalService.js';

const ViewEditAdminAccountForm = () => {
    const [adminList, setAdminList] = useState()
    const { confirm, success, error } = SwalAlert()
    const navigate = useNavigate();
    const molGridRef = useRef();

    const [searchRequest, setSearchRequest] = useState({
        //RoleId: null,
        SearchText: null,
    });
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 10,
        endPage: 100,
        SearchText: null,
        // ProjectTypeId: null,
        totalCount: 0,
    });

    const handleInputChange = (e) => {
        let temp = { ...searchRequest }
        temp["SearchText"] = e.target.value
        setSearchRequest(temp)
        getAdminList(temp);
    }   

    const [getAdminDetailList, {
        isSuccess: isSuccessAdminList,
        isFetching: isFetchingAdminList,
        data: adminListResponse
    }] = useGetAdminDetailListMutation()

    useEffect(() => {
        if (!isFetchingAdminList && isSuccessAdminList && adminListResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: adminListResponse.totalCount })
            setAdminList(adminListResponse.itemList);
        }
    }, [isFetchingAdminList, isSuccessAdminList, adminListResponse]);

    const handlePageChange = ({ pageNumber, pageSize }) => {
        getAdminList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }

    useEffect(() => {
        getAdminList()
    }, [])

    const getAdminList = (request) => {
        const req = {
            pageNo: 1,
            pageSize: 10,
            endPage: 100,
            SearchText: null,
            ...request
        }
        getAdminDetailList(req)
    }

    const handleEditClick = (data) => {
        navigate(`/ViewEditAdminAccount/${data.adminID}`);
    }

    const [deleteAdminTemplate, {
        isSuccess: isSuccessdeleteAdminTemplate,
        data: deleteAdminTemplateResponse
    }] = useDeleteAdminDetailsByIdMutation()

    useEffect(() => {
        if (isSuccessdeleteAdminTemplate && deleteAdminTemplateResponse) {
            if (deleteAdminTemplateResponse.hasError === false) {
                success("Admin Deleted Successfully");
                getAdminList();
            }
            else {
                error(deleteAdminTemplateResponse.errorMessage)
            }
        }
    }, [isSuccessdeleteAdminTemplate, deleteAdminTemplateResponse]);


    const handleDeleteClick = (data) => {
        confirm('Delete', "Are you sure you want to delete", 'Yes', 'Cancel')
            .then(value => {
                if (value) {

                    const request = {
                        id: data.adminID,
                        deletedBy: 115,
                        appId: 1
                    }
                    deleteAdminTemplate(request)
                }
            })
    }

    const actionHandler = {
        EDIT: handleEditClick,
        DELETE: handleDeleteClick
    }
    return (
        <div className='view-edit-admin-account-main'>
            <div className='email-template-management '>
                <div className="dropdown-management-main">
                    <div className="row align-items-center card-bg">

                        <div className="custom-table-sec">
                            <div className='d-flex justify-content-end'>

                                <div className='row'>
                                    <div className='col-lg-3 mb-3 my-3 px-2'>
                                        <Label labelName="Search:" />
                                    </div>
                                    <div className='col-lg-9 mb-3 my-2'>
                                        <Input
                                            id="searchText"
                                            name='SearchText'
                                            value={searchRequest.SearchText}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='email-template table-responsive'>
                                {adminList ?
                                    <MolGrid ref={molGridRef}
                                        configuration={GridConfig}
                                        dataSource={adminList}
                                        allowPagination={true}
                                        onActionChange={actionHandler}
                                        isLoading={isFetchingAdminList}
                                        pagination={paginationRequest}
                                        onPageChange={handlePageChange}
                                    />
                                    : <DataLoader />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ViewEditAdminAccountForm

//test