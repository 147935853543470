import AccountInformationData from "../component/accountInformation/data/AccountInformation.data";
import InstitutionDetailsData from "../component/institutionDetails/data/InstitutionDetails.data";
import MembershipSubscriptionData from "../component/membershipSubscription/data/MembershipSubscription.data";
import pointOfContactFData from "../component/pointOfContact/data/PointOfContact.data";

export const setViewAcadmicMemberDropDown=(apiResponse) => {
    const filterDropDownList=(dropdownName) => {
        return apiResponse.reduce((acc,item) => {
            if(item.dropdownName===dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        },[]);
    }

    const dropDownList={
        "accountStatus": filterDropDownList("Status"),
        "membershipTenure": filterDropDownList("MembershipTenure"),
        "membershipType": filterDropDownList("AcademicMembershipType"),
        "billingCategory": filterDropDownList("Academic Billing Category"),
        "joblevelId": filterDropDownList("JobLevel"),
        "salutationsID": filterDropDownList("Salutation"),
        "updatedBy": filterDropDownList("UpdatedBy"),
    };

    const formFields=[
        ...AccountInformationData.formFields,
        ...MembershipSubscriptionData.formFields,
        ...InstitutionDetailsData.formFields,
        ...pointOfContactFData.formFields
    ];

    const dropdownLists=dropDownList
    formFields.forEach(field => {
        const dropdownList=dropdownLists[field.id];
        if(dropdownList) {
            field.fieldSetting.options=dropdownList;
        }
    });
};
