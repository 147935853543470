import React, { forwardRef, useRef , useImperativeHandle} from 'react'
import FormCreator from '../../../../components/Forms/FormCreator';
import { avipAdditionalInformationData } from './data/avipAdditionalInformation.data';

const AvipAdditionalInformation = forwardRef(({ formData, handleOnChangeFileUpload, handleChangeOnFileDownload }, ref) => {
  const AvipAdditionalInformationRef = useRef();

  const handleFormFieldChange = (dataField, value) => {
    if ((dataField === "avipUpgradeFileName") && value) {
      handleOnChangeFileUpload(dataField, value)
    }

  };

  const handleDownload = (dataField, value) => {
    handleChangeOnFileDownload(dataField, value)
  };

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = AvipAdditionalInformationRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));


  const formActionHandler = {
    DOWNLOAD: handleDownload,
  }

  return (
    <div>
      <div className="row align-items-center card-bg">
        <FormCreator
          ref={AvipAdditionalInformationRef}
          {...avipAdditionalInformationData}
          onActionChange={formActionHandler}
          onFormFieldValueChange={handleFormFieldChange}
        ></FormCreator>
      </div>
    </div>
  )
}
)

export default AvipAdditionalInformation
