import { NavigateButton } from "../../../components/ui/navigateButton/NavigateButton";
import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";
import GenratePDF from "../../IndividualMemberTaskList/component/GenratePDF";


// gmapApplicationTasklistTableConfig 
export const corporateMemberTasklistTableConfig = {
    columns: [
        // {
        //     name: "Name",
        //     fieldName: "fullName",
        //     allowShort: false,
        // },
        {
            name: "Company Name",
            fieldName: "organisationName",
            allowShort: false,
        },
        {
            name: "Company UEN",
            fieldName: "uneNo",
            allowShort: false,
        },
        {
            name: "Membership Type",
            fieldName: "membershipTypeName",
            allowShort: false,
        },
        {
            name: "Transaction Type",
            fieldName: "transactionTypeName",
            allowShort: false,
        },
        {
            name: "Status",
            fieldName: "statusName",
            allowShort: false,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false,
            },
            customActions: [
                { name: "View", onClickAction: true },
                { name: "Delete", onClickAction: true },
            ],
        },
    ],
};



const corporateMemberTasklistFormData = {
    name: "memberShipTaskData",
    initialState: {
        uenNumber: "",
        membershipTypeId: "",
        statusId: "",
    },
    formFields: [
        {
            id: "uenNumber",
            lable: "Company Name/UEN",
            Field_Name: "UEN Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "uenNumber",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-5 mb-3",
            },
        },
        {
            id: "membershipTypeId",
            lable: "Membership Type",
            Field_Name: "Membership Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTypeId",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-5 mb-3"
            }
        },
        {
            id: "statusId",
            lable: "Status",
            Field_Name: "status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "statusId",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-5 mb-3"
            }
        },
    ],
};
export default corporateMemberTasklistFormData;


export const corporateFormData = {
    name: "corporateFormData",
    initialState: {
        nameOfOrgACRA: '',
        uenNo: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        postalCode: '',
        industryCode: '',
        acraFile: '',
        bilingCategory: '',
        updatedBy: '',
        // subscriptionStartDate: '',
        // subscriptionEndDate: '',
        // lastUpdatedDate: '',
        membershipTypeId: '',
        paymentTerms: '',
        isStartMembershipOn: true,
        startMembershipDate: '',
        membershipTenureId: '',
        entrenceFee: '',
        annualFee: '',
        complimentary: '',
        commentsToMember: '',
        internalNotes: ''
    },
    formFields: [
        {
            id: "organisationDetails",
            lable: "organisationDetails",
            Field_Name: "organisationDetails",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "organisationDetails",
            fieldSetting: {
                formTitle: "Organisation Details",
                subLabel: ""
            },
            style: {
                containerCss: "col-lg-6 mb-2  text-left form-tab-header",
            },
        },
        {
            id: "generatePdf",
            dataField: 'generatePdf',
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <GenratePDF type="Corporate" />,
            fieldSetting: {
                identifyNumber: 0,
            },
            style: {
                containerCss: "col-lg-6 mb-2 d-flex justify-content-end form-tab-header",
            }
        },
        {
            id: "nameOfOrgACRA",
            lable: "Name of Organisation as in ACRA",
            Field_Name: "Name of Organisation as in ACRA",
            fieldType: FormFieldTypes.INPUT,
            dataField: "nameOfOrgACRA",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "uenNo",
            lable: "BRN/UEN No",
            Field_Name: "BRN/UEN No",
            fieldType: FormFieldTypes.INPUT,
            dataField: "uenNo",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "addressLine1",
            lable: "Organisation Address Line 1",
            Field_Name: "Organisation Address Line 1",
            fieldType: FormFieldTypes.INPUT,
            dataField: "addressLine1",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "addressLine2",
            lable: "Organisation Address Line 2",
            Field_Name: "Organisation Address Line 2",
            fieldType: FormFieldTypes.INPUT,
            dataField: "addressLine2",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "addressLine3",
            lable: "Organisation Address Line 3",
            Field_Name: "Organisation Address Line 3",
            fieldType: FormFieldTypes.INPUT,
            dataField: "addressLine3",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "postalCode",
            lable: "Postal Code",
            Field_Name: "Postal Code",
            fieldType: FormFieldTypes.NUMERIC,
            dataField: "postalCode",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            id: "industryCode",
            lable: "Industry Code",
            Field_Name: "Industry Code",
            fieldType: FormFieldTypes.SELECT,
            dataField: "industryCode",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "acraFile",
            lable: 'ACRA BizFile (Please upload in pdf format max 2 mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'acraFile',
            fieldSetting: {
                placeholder: '',
                acceptedFiles: '.pdf',
                isDownloadable: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-2"
            }
        },
        {
            id: "navigatePath",
            dataField: 'navigatePath',
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <NavigateButton name={"View Corporate Information"} path={'/editCorporateMembers'} navStyle="theme-btn" />,
            fieldSetting: {
                identifyNumber: 0,
            },
            style: {
                containerCss: "col-lg-12 mb-3 text-end",
            }
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "membershipSubscription",
            lable: "MembershipSubscription",
            Field_Name: "MembershipSubscription",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "membershipSubscription",
            fieldSetting: {
                formTitle: "Membership Subscription",
                subLabel: ""
            },
            style: {
                containerCss: "col-lg-12 mb-3 text-left form-tab-header",
            },
        },
        {
            id: "membershipTypeId",
            lable: "Membership Type",
            Field_Name: "Membership Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTypeId",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: [],
                isDisable: true
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "bilingCategory",
            lable: "Billing Category",
            Field_Name: "Billing Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "bilingCategory",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: [],
                isDisable: true
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "updatedBy",
            lable: "Updated By",
            Field_Name: "Updated By",
            fieldType: FormFieldTypes.INPUT,
            dataField: "updatedBy",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: [],
                isDisable: true
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "subscriptionStartDate",
            lable: "Subscription Start Date",
            Field_Name: "Subscription Start Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionStartDate",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            id: "subscriptionEndDate",
            lable: "Subscription End Date",
            Field_Name: "Subscription End Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionEndDate",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            id: "lastUpdatedDate",
            lable: "Last Updated Date",
            Field_Name: "Last Updated Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "lastUpdatedDate",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2 d-none"
            }
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "pricingTable",
            lable: "pricingTable",
            Field_Name: "pricingTable",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "pricingTable",
            fieldSetting: {
                formTitle: "Pricing Table",
            },
            style: {
                containerCss: "col-lg-12 mb-2 text-left form-tab-header",
            },
        },
        {
            id: "membershipTypeId",
            lable: "Membership Type",
            Field_Name: "Membership Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTypeId",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            style: {
                containerCss: "col-lg-4 mb-3"
            }
        },

        {
            id: "isStartMembershipOn",
            lable: "Start Membership On",
            Field_Name: "Start Membership On",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "isStartMembershipOn",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-2 mt-4 text-nowrap",
            },
        },
        {
            id: "startMembershipDate",
            lable: "   ",
            Field_Name: "StartMembership Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "startMembershipDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                dateFormat: "MM/yyyy",
                showMonthYearPicker: true,

            },
            style: {
                containerCss: "col-lg-3 mb-3",
            },
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <></>,
            style: {
                containerCss: "col-lg-3",
            }
        },

        {
            id: "paymentTerms",
            lable: "Payment Terms",
            Field_Name: "Payment Terms",
            fieldType: FormFieldTypes.SELECT,
            dataField: "paymentTerms",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            style: {
                containerCss: "col-lg-4 mb-3"
            }
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <></>,
            style: {
                containerCss: "col-lg-8",
            }
        },
        {
            id: "membershipTenureId",
            lable: "Membership Tenure",
            Field_Name: "Membership Tenure",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTenureId",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            style: {
                containerCss: "col-lg-4 mb-3"
            }
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: <></>,
            style: {
                containerCss: "col-lg-8",
            }
        },
        {
            id: "entrenceFee",
            lable: "Entrance Fee",
            Field_Name: "Entrance Fee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "entrenceFee",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true
            },
            style: {
                containerCss: "col-md-2 mb-3",
            },
        },
        {
            id: "annualFee",
            lable: "Annual Fee",
            Field_Name: "Annual Fee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "annualFee",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true
            },
            style: {
                containerCss: "col-md-2 mb-3",
            },
        },
        {
            id: "complimentary",
            lable: "Complimentary Individual Membership",
            Field_Name: "Complimentary Individual Membership",
            fieldType: FormFieldTypes.INPUT,
            dataField: "complimentary",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true
            },
            style: {
                containerCss: "col-lg-6 mb-3",
            },
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "invoiceGenration",
            lable: "invoiceGenration",
            Field_Name: "invoiceGenration",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "invoiceGenration",
            fieldSetting: {
                formTitle: "Invoice Generation",
                subLabel: ""
            },
            style: {
                containerCss: "col-lg-12 mb-3 text-left form-tab-header",
            },
        },
        {
            id: "commentsToMember",
            lable: "Comments To Member",
            Field_Name: "Comments To Member",
            fieldType: FormFieldTypes.INPUT,
            dataField: "commentsToMember",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 mb-3",
            },
        },
        {
            id: "internalNotes",
            lable: "Internal Notes",
            Field_Name: "Internal Notes",
            fieldType: FormFieldTypes.INPUT,
            dataField: "internalNotes",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 mb-3",
            },
        },
    ],
};


