import { FormFieldTypes } from "../../../../../data/formFieldType";

const MembershipApplicationData = {
    name: "MembershipSubscriptionData",
    initialState: {
        membershipType: "",
        membershipTenure: "",
        preferredStartDate: "",
        membershipStartDate: "",
        membershipEndDate: "",
        billingCategory: "",
        membershipId: "",
    },
    formFields: [
        {
            id: "membershipType",
            lable: "Membership Type",
            Field_Name: "Membership Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipType",
            fieldSetting: {
                isDisable: false
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "membershipTenure",
            lable: "Membership Tenure",
            Field_Name: "Membership Tenure",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipTenure",
            fieldSetting: {
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "membershipStartDate",
            lable: "Membership Start Date",
            Field_Name: "Membership Start Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "membershipStartDate",
            fieldSetting: {
                dateFormat: "dd/MM/yyyy",
                // placeholder: "",
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "membershipEndDate",
            lable: "Membership End Date",
            Field_Name: "Membership End Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "membershipEndDate",
            fieldSetting: {
                dateFormat: "dd/MM/yyyy",
                // placeholder: "",
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "preferredStartDate",
            lable: "Preferred Start Date (MM/YYYY)",
            Field_Name: "Preferred Start Date (MM/YYYY)",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "preferredStartDate",
            fieldSetting: {
                dateFormat: "MM/yyyy",
                showMonthYearPicker: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "billingCategory",
            lable: "Billing Category",
            Field_Name: "Billing Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "billingCategory",
            fieldSetting: {
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "membershipId",
            lable: "Membership ID",
            Field_Name: "Membership ID",
            fieldType: FormFieldTypes.INPUT,
            dataField: "membershipId",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,
                options: [],
                isDisable: true
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3",
            },
        },
    ],
};

export default MembershipApplicationData;



