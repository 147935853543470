import React, { useEffect, useRef, useState } from "react";
import {
  NonMembeEDMFormData,
  NonMembeEDMTableConfig,
  UploadMemberExcelFileData,
} from "../data/UploadMemberExcelFile.Data";
import FormCreator from "../../../components/Forms/FormCreator";
import SwalAlert from "../../../services/swalService/SwalService";
import * as yup from "yup"; // Import yup for validation
import * as XLSX from "xlsx"; // Import XLSX for Excel file handling
import CenterModel from "../../../components/ui/centerModel/CenterModel";
import Buttons from "../../../components/ui/button/Buttons";
import {
  useAddEditIndividualNonMemberDetailsBatchMutation,
  useLazyDeleteNonMemberQuery,
  useGetAllNonIndividualMemberListMutation,
  useLazyGetNonIndividualMemberByIdQuery,
  useUpdateNonIndividualMemberDetailMutation,
  useLazyGetAllNonIndividualMemberListQuery,
} from "../../../app/services/EDM/EDMApi";
import MolGrid from "../../../components/Grid/MolGrid";
import { useNavigate } from "react-router-dom";
import ToastService from "../../../services/toastService/ToastService";
import { setFieldParameter } from "../utils/setEditFieldsUtils";
import { setAllDropDownField } from "../utils/setAllDropDownFields";
import { useLazyGetAllDropdownsForIndividualMembersQuery } from "../../../app/services/Individual/IndividualTaskListApi"

const UploadMemberExcelFile = ({ isIndividualNonMemberId }) => {
  const formRef = useRef();
  const nonMemberEditRef = useRef();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const molGridRef = useRef();
  const { error, success, confirmDelete } = SwalAlert();
  const [formData, setFormData] = useState(UploadMemberExcelFileData);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [dataSource, setDataSource] = useState();
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [sourceLabel, setSourceLable] = useState([]);
  const [sourceIds, setSourceIds] = useState([]);
  const [editFormData, setEditFormData] = useState(NonMembeEDMFormData);
  const [currentPage, SetCurrentPage] = useState(1);
  const [isFileSubmit, setIsFileSubmit] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [paginationRequest, setPaginationRequest] = useState({
    pageNo: 1,
    pageSize: 10,
    endPage: 100,
    SearchText: "",
    totalCount: 0,
    StatusId: null,
  });
  const [deleteRecord, { data: deleteResponse, isSuccess: isDeleteSuccess, isLoading: isDeleteLoading, }] = useLazyDeleteNonMemberQuery()
  const [getNonMemberByid, { data: getNonIndividualMemberDetails, isSuccess: isGetNonIndividualMemberSuccess, isLoading: isGetNonIndividualMemberLoading, }] = useLazyGetNonIndividualMemberByIdQuery()
  const [updateNonMember, { data: updateNonIndividualMemberDetail, isSuccess: isUpdateNonIndividualMemberSuccess, isLoading: isUpdateNonIndividualMemberLoading, }] = useUpdateNonIndividualMemberDetailMutation()
  const [getAllDropDowns, { data: dropdownResponse, isSuccess: isSuccessDropdown, isLoading: isLoadingDropdown, }] = useLazyGetAllDropdownsForIndividualMembersQuery();

  const [
    uploadMemberExcel,
    {
      data: uploadExcelRes,
      isSuccess: isSuccessUpload,
      isLoading: isLoadingUpload,
    },
  ] = useAddEditIndividualNonMemberDetailsBatchMutation();
  const [
    getAllNonMemberList,
    { data: nonMemberList, isSuccess: isSuccess, isLoading: isLoading },
  ] = useGetAllNonIndividualMemberListMutation();

  useEffect(() => {
    setDataSource([]);
    getAllDropDowns();
    getAllNonMemberList(paginationRequest);
    // let updateTag = setSourceTagDropdown()
  }, []);
  // Effect to set dropdown fields once data is loaded
  useEffect(() => {
    if (!isLoadingDropdown && isSuccessDropdown && dropdownResponse) {
      var sourceTagData = setAllDropDownField(dropdownResponse);
      setTagData(sourceTagData.source);
    }
  }, [isLoading, isSuccess, dropdownResponse])

  //Delete non member API response
  useEffect(() => {
    if (!isDeleteLoading && isDeleteSuccess && deleteResponse) {
      ToastService.success("Delete Item successfully");
      getAllNonMemberList({
        ...paginationRequest,
        pageNo: currentPage,
      });
    }
  }, [isDeleteLoading, isDeleteSuccess, deleteResponse])
  // Get non member list API reponse
  useEffect(() => {
    if (!isLoading && isSuccess && nonMemberList) {
      setPaginationRequest({
        ...paginationRequest,
        totalCount: nonMemberList.totalCount,
      });
      setDataSource(nonMemberList.itemList);
    }
  }, [isLoading, isSuccess, nonMemberList]);
  // File upload and file submit response
  useEffect(() => {
    if (!isLoadingUpload && isSuccessUpload && uploadExcelRes) {
      const isNewMemberNotExists =
        uploadExcelRes?.membersDetails?.filter((item) => item.errorType === "")
          .length > 0;
      setData(uploadExcelRes?.membersDetails);
      setIsSubmitSuccess(uploadExcelRes?.isNonMember);
      success(
        !uploadExcelRes?.isNonMember
          ? "Member Added Successfully"
          : "Member Validate Successfully",
        ""
      );
      if (isFileSubmit) {
        handleModalClose();
      }
    }
  }, [isLoadingUpload, isSuccessUpload, uploadExcelRes]);

  const schema = yup.object().shape({
    First_Name: yup.string().required("First Name is required"),
    Email_Address: yup
      .string()
      .email("Invalid email")
      .required("Email is required"),
  });
  const validateData = async (jsonData) => {
    const validationErrors = [];
    for (let i = 0; i < jsonData.length; i++) {
      try {
        await schema.validate(jsonData[i], { abortEarly: false });
      } catch (err) {
        validationErrors.push({ row: i + 1, errors: err.errors });
      }
    }
    setErrors(validationErrors);
  };
  // Handle file upload method
  const handleFileUpload = (event, flag) => {
    setData([]);
    const file = event.target.files[0];
    setFileName(file?.name);
    setIsUploadSuccess(false);
    setIsFileSubmit(flag);
    if (!file) {
      error("No file selected", "");
      return;
    }
    setFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      // setData(jsonData);
      validateData(jsonData);
      setShowModal(true);
      const request = {
        membersDetails: jsonData.map((item) => ({
          FirstName: item.First_Name,
          LastName: item.Last_Name,
          Email: item.Email_Address,
          IsEDM: 1,
          IsBulletin: 1,
          IsNewsletter: 1,
          SourceTag: sourceIds?.join(', '),
          IsNonMember: false,
        })),
      };
      uploadMemberExcel(request);
    };
    reader.onerror = (e) => {
      console.error("Error reading file:", e);
    };
    reader.readAsArrayBuffer(file);
  };
  // Handle modal close response
  const handleModalClose = () => {
    setShowModal(false);
    setIsFileSubmit(false);
    setFile(null); // Reset file state
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input value
    }
    getAllNonMemberList(paginationRequest);
  };
  // Handle file submit method
  const handleSubmit = (flag) => {
    const newMemberRecord = data?.filter((item) => item.errorType === "");
    const request = {
      membersDetails: newMemberRecord?.map((item) => ({
        FirstName: item.firstName,
        LastName: item.lastName,
        Email: item.email,
        IsEDM: 1,
        IsBulletin: 1,
        IsNewsletter: 1,
        //SourceTag: sourceLabel?.value,
        SourceTag: sourceIds?.join(', '),
        IsNonMember: true,
      })),
    };
    setIsFileSubmit(flag);
    uploadMemberExcel(request);
  };
  // Handle page chagne method
  const handlePageChange = ({ pageNumber, pageSize }) => {
    SetCurrentPage(pageNumber);
    getAllNonMemberList({
      ...paginationRequest,
      pageNo: pageNumber,
      pageSize: pageSize,
    });
  };
  // Handle form field change, specifically for file upload
  const handleFormFieldChange = (dataField, value) => {
    if (dataField === "TagName" && value) {
      const labelArr = value.map(num => {
        let obj = tagData.find(item => item.value === num);
        return obj ? obj.label : null;
      });
      setSourceLable(labelArr);
      setSourceIds(value);
    }
  };
  // Handle delete table row method 
  const handleTableDelete = (individualNonMemberId) => {
    const request = {
      id: individualNonMemberId
    }
    confirmDelete("Are you sure?", "Type 'delete' to confirm", "Delete", "Cancel")
      .then((confirmed) => {
        if (confirmed) {
          deleteRecord(request);
        }
      });
  };
  // View non member detail method
  const handleViewClick = (individualNonMemberId) => {
    getNonMemberByid(individualNonMemberId);
    setIsModalOpen(true);
  };
  // On handle action click method
  const handleCustomActionClick = (rowData, buttonName) => {
    if (buttonName === "Delete") {
      handleTableDelete(rowData?.individualNonMemberId)
    } else {
      handleViewClick(rowData?.individualNonMemberId)
    }
  };
  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  }
  // Get non individual member details response
  useEffect(() => {
    if (!isGetNonIndividualMemberLoading && isGetNonIndividualMemberSuccess && getNonIndividualMemberDetails) {
      setFieldParameter(getNonIndividualMemberDetails, editFormData);
    }
  }, [isGetNonIndividualMemberLoading, isGetNonIndividualMemberSuccess, getNonIndividualMemberDetails]);
  //Edit functionality
  useEffect(() => {
    if (!isUpdateNonIndividualMemberLoading && isUpdateNonIndividualMemberSuccess && updateNonIndividualMemberDetail) {
      success("", "Application Updated Successfully ");
      setIsModalOpen(false);
      getAllNonMemberList({
        ...paginationRequest,
        pageNo: currentPage,
      });
    }
  }, [isUpdateNonIndividualMemberLoading, isUpdateNonIndividualMemberSuccess, updateNonIndividualMemberDetail]);
  // Handle update non member details 
  const handleUpdateNonMember = (formData) => {
    let formResponse = nonMemberEditRef.current.getFormData() || formData;
    if (!formResponse) {
      return error("Please fill all mandatory fields.", "")
    }
    const request = {
      IndividualNonMemberId: getNonIndividualMemberDetails?.individualNonMemberId,
      MemberFirstName: formResponse?.memberFirstName || null,
      MemberLastName: formResponse?.memberLastName || null,
      Email: formResponse?.email || null,
      SourceTag: formResponse?.source?.value || formResponse?.source?.value || formResponse?.source.join(', '),
      EDM: formResponse?.EDMCheckbox || false,
      Bulletin: formResponse?.bulletCheckbox || false,
      NewsLetter: formResponse?.newsLetterCheckBox || false,
    }
    updateNonMember(request);
  }

  const actionHandler = {
    "CUSTOM_ACTION_CLICK": handleCustomActionClick
  };

  return (
    <React.Fragment>
      <div className="row">
        <FormCreator
          // onActionChange={formActionHandler}
          ref={formRef}
          {...formData}
          onFormFieldValueChange={handleFormFieldChange}
        />
        <div className="col-lg-4">
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={() => fileInputRef.current.click()}
              className="btn theme-btn margin-top-uploadbtn"
              disabled={sourceLabel?.length > 0 ? false : true}
            >
              Upload File
            </button>
            <a
              className="btn outline-btn ml-3"
              href={`${process.env.PUBLIC_URL}/EDMMaillingLists/EDM_Template new1.xlsx`}
              download
            >
              Download Template
            </a>
          </div>
          <input
            ref={fileInputRef}
            type="file"
            accept=".xlsx, .xls"
            onChange={(event) => handleFileUpload(event, false)}
            className="hidden-upload-input"
            //disabled={sourceLabel?.value > 0 ? false : true}
            disabled={sourceLabel?.length > 0 ? false : true}
          />
        </div>
        {showModal && (
          <CenterModel
            modelTitle={`${fileName}`}
            show={showModal}
            handleClose={handleModalClose}
            onUpload={handleModalClose}
          >
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12  table-responsive">
                  <table className="table">
                    <thead className="">
                      <tr>
                        <th>No</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Source Tag</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item, rowIndex) => {
                        const rowErrors = errors.find(
                          (error) => error.errorType === rowIndex + 1
                        );
                        const hasError = item?.firstName === "" || item?.firstName === null || item?.email === "" || item?.email === null
                          ? "bg-danger text-light" : "bg-success text-light";
                        return (
                          <tr key={rowIndex} className={hasError}>
                            <td className={hasError} >{rowIndex + 1}</td>
                            <td className={hasError}>{item?.firstName}</td>
                            <td className={hasError}>{item?.lastName}</td>
                            <td className={hasError}>{item?.email}</td>
                            <td className={hasError}>{sourceLabel?.join(', ')}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {isUploadSuccess && (
                    <React.Fragment>
                      <h4 className="mt-5">Status:</h4>
                      <hr />
                      <table border="1" className="mt-2">
                        <thead>
                          <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Message</th>
                            <th>Status</th>
                            <th className="d-flex justify-content-center align-items-center"></th>
                          </tr>
                        </thead>
                        <tbody>

                        </tbody>
                      </table>
                    </React.Fragment>
                  )}
                </div>
                <div className="col-lg-12">
                  {errors.length > 0 && (
                    <div className="mt-3">
                      <h5>
                        Errors: Incomplete data will not be upload into the system
                      </h5>
                      <ul>
                        {errors.map((error, index) => (
                          <li key={index}>
                            Row {error.row}: {error.errors.join(", ")}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {!isUploadSuccess && isSubmitSuccess && (
                  <div className="col-lg-12 d-flex justify-content-end mt-4">
                    <div>
                      <Buttons
                        buttonTypeClassName="outline-btn"
                        buttonText="Cancel"
                        onClick={handleModalClose}
                      />
                    </div>
                    <div className="ml-2">
                      <Buttons
                        buttonTypeClassName="theme-btn"
                        buttonText="Submit"
                        onClick={() => handleSubmit(true)}
                      // isLoading={type === "cpp" ? isLoadingEmp : type === "app" ? isLoadingApp : isStuLoading}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </CenterModel>
        )}
      </div>
      <div className="row">
        <h1 className="account-info-title">
          Total :{paginationRequest.totalCount}
        </h1>
        <div className="col-lg-12">
          <MolGrid
            ref={molGridRef}
            configuration={NonMembeEDMTableConfig}
            dataSource={dataSource}
            allowPagination={true}
            isLoading={isLoading}
            pagination={paginationRequest}
            onPageChange={handlePageChange}
            onActionChange={actionHandler}
          />
        </div>
        {isModalOpen &&
          <CenterModel
            modelTitle={'Individual Non Member'}
            show={"test"}
            handleClose={openModal}
            onUpload={openModal}
          >
            <div className="container">
              <div className="row">
                <FormCreator
                  onActionChange={actionHandler}
                  ref={nonMemberEditRef}
                  {...editFormData}
                //  onFormFieldValueChange={handleFormFieldChange}
                />
                <div className="col-lg-12 d-flex justify-content-center">
                  <Buttons
                    buttonTypeClassName="theme-btn"
                    buttonText="Save"
                    onClick={handleUpdateNonMember}
                  // isLoading={isLoadingFalg}
                  //isDisable={isDeleteLoading}
                  />
                </div>
              </div>
            </div>
          </CenterModel>
        }
      </div>
    </React.Fragment>
  );
};

export default UploadMemberExcelFile;
