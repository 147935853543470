import { FormFieldTypes } from "../../../../../data/formFieldType";

export const avipAdditionalInformationData = {
    name: "AvipAdditionalInformation",
    initialState: {
        uploadAvipFile: "",
    },
    formFields: [
        {
            id: "uploadAvipFile",
            lable: 'AVIP Additional Information (please upload in pdf format max 2mb)',
            Field_Name: 'AVIP Additional Information (please upload in pdf format max 2mb)',
            fieldType: FormFieldTypes.FILE,
            dataField: 'uploadAvipFile',
            fieldSetting: {
                placeholder: '',
                isDownloadable: true,
                acceptedFiles: '.pdf',
                maxFileSizeInKB: 2048,
                
            },
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
};