//*** Lib */

import {
    createCookie,
    getCookie,
    isCookieExist,
    removeCookie,
    setCookie
} from "../utils/Cookies/CookieHandler";
import {
    removeData,
    getData
} from "../utils/LocalStorage/LocalStorageManager";
const authCookieName = 'AdminAuthUser';
const tokenCookieName = 'AdminToken';
const securityPermission = 'SecurityPermission';

export const setAuthProps = (data) => {
    const authProps = {
        cookieName: authCookieName,
        cookieValue: data,
        expirationTime: data.sessionTimeout
    }
    createCookie(authProps);
    setTokenProps(data.token);
}
// Set cookies after otp validation
export const setAuthPropsOnOTP = (data) => {
    if (data) {
        let setAuthData = getData("authData")
        const authProps = {
            cookieName: authCookieName,
            cookieValue: setAuthData,
            expirationTime: setAuthData.sessionTimeout
        }
        createCookie(authProps);
        setTokenProps(setAuthData.token);
    }
}

export const setSecurityPermission = (data, sessionTimeout) => {
    const authProps = {
        cookieName: securityPermission,
        cookieValue: data,
        expirationTime: sessionTimeout
    }
    createCookie(authProps);
}

export const getAuthProps = () => {
    return getCookie(authCookieName);
}

export const isAuthorized = () => {
    return isCookieExist(authCookieName);
}

export const setTokenProps = (data) => {
    const tokenProps = {
        cookieName: tokenCookieName,
        cookieValue: data
    }
    setCookie(tokenProps);
}

export const getTokenProps = () => {
    return getCookie(tokenCookieName);
}

export const signOut = () => {
    removeCookie(authCookieName, { path: '/' });
    removeCookie(tokenCookieName, { path: '/' });
    // removeAllCookies();
    removeData("isPIC1");
    removeData("pic1Name");
    removeData("authData");
    window.location.href = "/login";
}