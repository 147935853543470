import CorporateAccountInformationData from "../component/accountInformation/data/CorporateAccountInformation.data";
import CorporateMembershipSubscriptionData from "../component/membershipSubscription/data/MembershipSubscription.data";
import OrganisationDetailsData from "../component/organisationDetails/data/OrganisationDetails.data";
import CorporatePointOfContactData from "../component/pointOfContact/data/CorporatePointOfContact.data";

export const setViewCorporateMemberDropDown = (apiResponse) => {
    const filterDropDownList = (dropdownName) => {
        return apiResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        }, []);
    }

    const dropDownList = {
        "accountStatus": filterDropDownList("Status"),
        "membershipTenure": filterDropDownList("MembershipTenure"),
        "contactPersonsDesignation": filterDropDownList("Designation"),
        "membershipType": filterDropDownList("CorporateMembershipType"),
        "industryCode": filterDropDownList("IndustryCode"),
        "billingCategory" : filterDropDownList("Billing Category"),
        "jobLevelId": filterDropDownList("JobLevel"),
        "salutationsID": filterDropDownList("Salutation"),
        "updatedBy": filterDropDownList("UpdatedBy"),

    };

    const formFields = [
        ...CorporateAccountInformationData.formFields,
        ...CorporateMembershipSubscriptionData.formFields,
        ...OrganisationDetailsData.formFields,
        ...CorporatePointOfContactData.formFields
    ];

    const dropdownLists = dropDownList
    formFields.forEach(field => {
        const dropdownList = dropdownLists[field.id];
        if (dropdownList) {
            field.fieldSetting.options = dropdownList;
        }
    });
};
