import React, { useDebugValue, useEffect, useRef, useState } from "react";
import Buttons from "../../../components/ui/button/Buttons";
import { useNavigate } from "react-router-dom";
import FormCreator from "../../../components/Forms/FormCreator";
import { AddEditCampaignFormData } from "../data/AddEditCampaignForm.data";
import {
  useAddEditEmailCampaignMutation,
  useGetRecipientsByMembershipTypeDetailsMutation,
  useGetSelectedEmailCountForCampaignMutation,
  useLazyGetEmailTemplateByIdQuery,
  useLazyGetFilterDropdownsQuery
} from "../../../app/services/EmailCampaign/EmailCampaignApi";
import { setEmailCampaignDropDown } from "../utils/setEmailCampaignDropDown";
import SwalAlert from "../../../services/swalService/SwalService";
import {
  useLazyGetPDFQuery,
  useLazyGetImageQuery,
  useStoreBase64ImageMutation,
  useUploadPDFMutation
} from "../../../app/services/Common/CommonApi";
import ToastService from "../../../services/toastService/ToastService";
import moment from "moment";
import { saveAs } from 'file-saver';
import { setRecipientsFilter, removeValidationOnDraft } from "../utils/editEmialCampaifnInitials";

const AddEdiCampaignForm = () => {
  const navigate = useNavigate();
  const emailFromRef = useRef();
  const [campaignFormData, setCampaignFormData] = useState(AddEditCampaignFormData)
  const { error, success } = SwalAlert()
  let forInitials = useRef()

  let [uploadedAttachment, setuploadedAttachment] = useState(null);
  const [statusToSubmit, setStatusToSubmit] = useState(null);


  const [uploadImage,
    { isSuccess: isSuccessImage,
      isLoading: isLoadingImage,
      data: profileImageUpload }
  ] = useStoreBase64ImageMutation();

  const [getPDF,
    { isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData }] = useLazyGetPDFQuery();

  const [getImage,
    { isSuccess: isSuccessGetImage,
      isLoading: isLoadingGetImage,
      data: getImageData }
  ] = useLazyGetImageQuery();

  const [getEmailTemplateById,
    { isSuccess: getEmailisSuccess,
      isLoading: getEmailisLoading,
      data: getEmailData }
  ] = useLazyGetEmailTemplateByIdQuery();

  useEffect(() => {
    if (getEmailisSuccess && !getEmailisLoading && getEmailData) {
      let temp = { ...campaignFormData };
      temp.initialState.message = getEmailData.description;
      setCampaignFormData(temp);
    }
  }, [getEmailisSuccess, getEmailisLoading, getEmailData,]);


  const [uploadPDF,
    { isSuccess: isFileUploadSuccess,
      data: fileUploadData }
  ] = useUploadPDFMutation();

  const [getRecipients,
    { isSuccess: getRecipientsSuccess,
      isLoading: getRecipientsLoading,
      data: getRecipientsData }
  ] = useGetRecipientsByMembershipTypeDetailsMutation();

  const [getSelectedCount,
    { isSuccess: getSelectedCountSuccess,
      isLoading: getSelectedCountLoading,
      data: getSelectedCountData }
  ] = useGetSelectedEmailCountForCampaignMutation();


  const [addEditEmailCampaign,
    { isSuccess: addEditEmailCampaignSuccess,
      isLoading: addEditEmailCampaignLoading,
      data: addEditEmailCampaignData }
  ] = useAddEditEmailCampaignMutation();

  useEffect(() => {
    if (addEditEmailCampaignSuccess && !addEditEmailCampaignLoading && addEditEmailCampaignData) {
      success("Campaign Added Successfully");
      navigate('/EmailManagement')
    }
  }, [addEditEmailCampaignSuccess, addEditEmailCampaignLoading, addEditEmailCampaignData]);

  useEffect(() => {
    if (getRecipientsSuccess && !getRecipientsLoading && getRecipientsData) {
      const updatedRecipients = setRecipientsFilter(getRecipientsData, campaignFormData)
      setCampaignFormData({ ...updatedRecipients });
    }
  }, [getRecipientsSuccess, getRecipientsLoading, getRecipientsData,]);

  useEffect(() => {
    if (getSelectedCountSuccess && !getSelectedCountLoading && getSelectedCountData) {
      const updatedRecipients = setRecipientsFilter(getSelectedCountData.itemList, campaignFormData);
      setCampaignFormData({ ...updatedRecipients });
    }
  }, [getSelectedCountSuccess, getSelectedCountLoading, getSelectedCountData,]);


  const [getFilterDropdowns,
    { isSuccess: getFilterDropdownsSuccess,
      isLoading: getFilterDropdownsLoading,
      data: getFilterDropdownsData }
  ] = useLazyGetFilterDropdownsQuery();

  useEffect(() => {
    if (getFilterDropdownsSuccess && !getFilterDropdownsLoading && getFilterDropdownsData) {
      setEmailCampaignDropDown(getFilterDropdownsData)
    }
  }, [getFilterDropdownsSuccess, getFilterDropdownsLoading, getFilterDropdownsData,]);


  useEffect(() => {
    getRecipients()
    getFilterDropdowns()
    getEmailTemplateById('15')
  }, [])

  const getCommaSepratedRecipients = (data) => {
    const memebrship = [
      { id: "avip", membershipTypeId: 1 },
      { id: "path1", membershipTypeId: 2 },
      { id: "path2", membershipTypeId: 3 },
      { id: "path3", membershipTypeId: 4 },
      { id: "associateMember", membershipTypeId: 5 },
      { id: "cppStartUp", membershipTypeId: 6 },
      { id: "cppsme", membershipTypeId: 7 },
      { id: "cpp", membershipTypeId: 8 },
      { id: "cpp100", membershipTypeId: 9 },
      { id: "academic", membershipTypeId: 10 },
      { id: "affiliate", membershipTypeId: 11 },
      { id: "fellow", membershipTypeId: 12 },
    ]

    const recipientsSelectd = memebrship.map((obj) => (
      data[obj.id] === true ? obj.membershipTypeId : null)).filter((i) => i !== null).join(', ');
    return recipientsSelectd !== "" ? recipientsSelectd : null;
  }

  const safeJoin = (value) => (value && Array.isArray(value) ? value.join(', ') : '');

  const getSelectedEmailCount = (stateValues) => {

    const request = {
      IncludeEmail: stateValues?.includeEmail || null,
      ExcludeEmail: stateValues?.excludeEmail || null,
      Recipients: getCommaSepratedRecipients(stateValues),
      RecipientsByGender: safeJoin(stateValues?.recipientsByGenderSelect),
      RecipientsByJobTitle: safeJoin(stateValues?.recipientsByJobTitleSelect),
      RecipientsByNationality: safeJoin(stateValues?.recipientsByNationalitySelect),
      RecipientsByIndustry: safeJoin(stateValues?.recipientsByIndustrySelect),
      RecipientsByTagging: safeJoin(stateValues?.recipientsByTaggingSelect),
      RecipientsByStatus: safeJoin(stateValues?.recipientsByStatusSelect),
      RecipientsByRace: safeJoin(stateValues?.recipientsByRaceSelect),
      RecipientsByResidenceStatus: safeJoin(stateValues?.recipientsByResidenceStatusSelect),
      RecipientsByCampaignType: stateValues?.recipientsByCampaignTypeSelect?.value,
    }
    getSelectedCount(request)
  }

  const updateInitialState = (formData, updates) => {

    formData.initialState = {
      ...forInitials,
      ...updates
    };
    setCampaignFormData(formData);
    getSelectedEmailCount(formData.initialState);

  };

  const handleAttachment = async (data) => {
    try {
      if (data?.fileName?.includes('.jpg') || data?.fileName?.includes('.png')) {
        const { base64Data, fileName } = data
        let saveFileRequest = {
          fileName: fileName,
          fileStr: base64Data,
          storagePath: "CampaignAttachment",
        }
        const response = await uploadImage(saveFileRequest)
        setuploadedAttachment(response?.data?.fileName)
        ToastService.success("Image Upload Successfully")
      }
      else {
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", "CampaignAttachment");
        const response = await uploadPDF(formData);
        setuploadedAttachment(response?.data?.fileName)
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }

  const updateDateAndTimeFields = (isDisable) => {
    let formData = { ...campaignFormData };
    formData.formFields = formData.formFields.map(field => {
      if (field.id === "date" || field.id === "time") {
        return { ...field, fieldSetting: { ...field.fieldSetting, isDisable } };
      }
      return field;
    });
    formData.initialState = {
      ...forInitials,
      sendDate: null,
      sendTime: null,
    };
    setCampaignFormData(formData);
  }

  const updateRecipientsState = (fieldId, stateKey, value) => {
    let formData = { ...campaignFormData };
    const field = formData.formFields.find(field => field.id === fieldId);

    if (field.fieldSetting.options.length === value.length) {
      updateInitialState(formData, { [stateKey]: true });
    }
    else if (formData.initialState[stateKey] !== false) {
      updateInitialState(formData, { [stateKey]: false });
    }
    else {
      getSelectedEmailCount(forInitials);
    }
  };


  const getAllDropdownValues = (dropdownName) => {
    return getFilterDropdownsData?.filter(obj => obj.dropdownName === dropdownName)?.map(obj => Number(obj.id));
  }

  const onFormFieldValueChange = (fieldName, value) => {

    let formData = { ...campaignFormData };
    switch (fieldName) {
      case "attachment":
        { handleAttachment(value); break; }
      case "send":
        {
          if (value === "SendNow") {
            updateDateAndTimeFields(true);
          } else {
            updateDateAndTimeFields(false);
          }
          break;
        }
      case "recipientsByMembershipType":
        updateInitialState(formData, {
          recipientsByMembershipType: value,
          aispOrdinaryMember: value,
          path1: value,
          path2: value,
          path3: value,
          associateMember: value,
          affiliate: value,
          avip: value,
          fellow: value,
          cppStartUp: value,
          cppsme: value,
          cpp: value,
          cpp100: value,
          academic: value,
        });
        break;
      case "aispOrdinaryMember":
        updateInitialState(formData, {
          path1: value,
          path2: value,
          path3: value,
        });
        break;
      case "path1":
      case "path2":
      case "path3":
        {
          if (forInitials.path1 && forInitials.path2 && forInitials.path3) {
            updateInitialState(formData, {
              aispOrdinaryMember: true,
            });
          }
          else if (formData.initialState.aispOrdinaryMember !== false) {
            updateInitialState(formData, {
              aispOrdinaryMember: false,
            });
          }
          else {
            getSelectedEmailCount(forInitials);
          }
          break;
        }
      case "associateMember":
      case "affiliate":
      case "avip":
      case "fellow":
      case "cppStartUp":
      case "cppsme":
      case "cpp":
      case "cpp100":
      case "academic":
        { getSelectedEmailCount(forInitials); break; }
      case "recipientsByGenderSelect":
        updateRecipientsState("recipientsByGenderSelect", "recipientsByGender", value);
        break;
      case "recipientsByJobTitleSelect":
        updateRecipientsState("recipientsByJobTitleSelect", "recipientsByJobTitle", value);
        break;
      case "recipientsByNationalitySelect":
        updateRecipientsState("recipientsByNationalitySelect", "recipientsByNationality", value);
        break;
      case "recipientsByIndustrySelect":
        updateRecipientsState("recipientsByIndustrySelect", "recipientsByIndustry", value);
        break;
      case "recipientsByTaggingSelect":
        updateRecipientsState("recipientsByTaggingSelect", "recipientsByTagging", value);
        break;
      case "recipientsByStatusSelect":
        updateRecipientsState("recipientsByStatusSelect", "recipientsByStatus", value);
        break;
      case "recipientsByRaceSelect":
        updateRecipientsState("recipientsByRaceSelect", "recipientsByRace", value);
        break;
      case "recipientsByResidenceStatusSelect":
        updateRecipientsState("recipientsByResidenceStatusSelect", "recipientsByResidenceStatus", value);
        break;
      case "recipientsByCampaignTypeSelect":
        updateRecipientsState("recipientsByCampaignTypeSelect", "recipientsByCampaignType", value);
        break;
      case "recipientsByGender":
        updateInitialState(formData, {
          recipientsByGender: value,
          recipientsByGenderSelect: value ? getAllDropdownValues("Gender") : '',
        });
        break;
      case "recipientsByJobTitle":
        updateInitialState(formData, {
          recipientsByJobTitle: value,
          recipientsByJobTitleSelect: value ? getAllDropdownValues("JobLevel") : '',
        });
        break;
      case "recipientsByNationality":
        updateInitialState(formData, {
          recipientsByNationality: value,
          recipientsByNationalitySelect: value ? getAllDropdownValues("Nationality") : '',
        });
        break;
      case "recipientsByIndustry":
        updateInitialState(formData, {
          recipientsByIndustry: value,
          recipientsByIndustrySelect: value ? getAllDropdownValues("IndustryCode") : '',
        });
        break;
      case "recipientsByTagging":
        updateInitialState(formData, {
          recipientsByTagging: value,
          recipientsByTaggingSelect: value ? getAllDropdownValues("Tag") : '',
        });
        break;
      case "recipientsByStatus":
        updateInitialState(formData, {
          recipientsByStatus: value,
          recipientsByStatusSelect: value ? getAllDropdownValues("Status")?.filter(value => [8, 16, 17, 18, 19, 20].includes(value)) : '',
        });
        break;
      case "recipientsByRace":
        updateInitialState(formData, {
          recipientsByRace: value,
          recipientsByRaceSelect: value ? getAllDropdownValues("Race") : '',
        });
        break;
      case "recipientsByResidenceStatus":
        updateInitialState(formData, {
          recipientsByResidenceStatus: value,
          recipientsByResidenceStatusSelect: value ? getAllDropdownValues("ResidentStatus") : '',
        });
        break;
      case "recipientsByCampaignType":
        updateInitialState(formData, {
          recipientsByCampaignType: value,
          recipientsByCampaignTypeSelect: value ? getAllDropdownValues("CampaignType") : '',
        });
        break;
    }
  };



  // const onSubmit = (status) => {
  //   debugger
  //   if (status === 'Draft') {
  //     let updatedForm = removeValidationOnDraft(status, campaignFormData);
  //     setCampaignFormData(updatedForm);
  //     setStatusToSubmit(status); // Set status for submission after state update
  //   } else {
  //     submitCampaign(status);
  //   }
  // };

  // // Use effect to trigger submitCampaign after campaignFormData is updated
  // useEffect(() => {
  //   if (statusToSubmit) {
  //     submitCampaign(statusToSubmit);
  //     setStatusToSubmit(null); // Reset statusToSubmit after submission
  //   }
  // }, [campaignFormData, statusToSubmit]);

  // const submitCampaign = (status) => {
  //   debugger
  //   const isDraft = status === "Draft";
  //   const data = emailFromRef?.current?.getFormData();

  //   let dateTimeCheck = true;
  //   if (data && data.send === "SendLater") {
  //     dateTimeCheck = data.sendDate !== null && data.sendDate !== "" && data.sendTime !== null && data.sendTime !== "";
  //   }

  //   if (status !== "Draft" && !dateTimeCheck) {
  //     error("Date and time must be filled when you select Send Later");
  //   } 
  //   else if (data) {
  //     const request = {
  //       EmailCampaignId: 0,
  //       CampaignName: data.campaignName,
  //       Subject: data.subject,
  //       IncludeEmail: data.includeEmail || null,
  //       ExcludeEmail: data.excludeEmail || null,
  //       Message: data.message,
  //       Recipients: getCommaSepratedRecipients(data) || null,
  //       Attachment: uploadedAttachment || null,
  //       SendNow: data.send === "SendNow",
  //       SendLater: data.send === "SendLater",
  //       SendDate: data.send === "SendNow" ? moment().format('YYYY-MM-DD') : data.sendDate,
  //       SendTime: data.send === "SendNow" ? moment().add(3, 'minutes').format('HH:mm') : data.sendTime,
  //       RecipientsByGender: safeJoin(data.recipientsByGenderSelect),
  //       RecipientsByJobTitle: safeJoin(data.recipientsByJobTitleSelect),
  //       RecipientsByNationality: safeJoin(data.recipientsByNationalitySelect),
  //       RecipientsByIndustry: safeJoin(data.recipientsByIndustrySelect),
  //       RecipientsByTagging: safeJoin(data.recipientsByTaggingSelect),
  //       RecipientsByStatus: data.recipientsByStatusSelect?.join(', '),
  //       RecipientsByRace: data.recipientsByRaceSelect?.join(', '),
  //       RecipientsByResidenceStatus: data.recipientsByResidenceStatusSelect?.join(', '),
  //       RecipientsByCampaignType: data?.recipientsByCampaignTypeSelect?.value || data?.recipientsByCampaignTypeSelect,
  //       Summary: "",
  //       CC: "",
  //       Status: status,
  //       AppId: 114,
  //     };

  //     addEditEmailCampaign(request);
  //   } else {
  //     error("Fill in all required fields Hahahah.");
  //   }
  // };


  const onSubmit = (status) => {
    // if (status === "Draft") {
    // var updatedForm = removeValidationOnDraft(status, campaignFormData);
    // setCampaignFormData(updatedForm);
    // }
    // debugger
    const isDraft = status === "Draft";
    const data = emailFromRef?.current?.getFormData();

    let dateTimeCheck = true;
    if (data && data.send === "SendLater") {
      dateTimeCheck = data.sendDate !== null && data.sendDate !== "" && data.sendTime !== null && data.sendTime !== "";
    }
    if (status !== "Draft" && !dateTimeCheck) {
      error("Date and time must be filled when you select Send Later");
    }
    else if (status !== "Draft" && !data.subject) {
      error("Please enter the subject");
    }
    else if (status !== "Draft" && !data.message) {
      error("Please enter the message");
    }
    // else if (status !== "Draft" && data.recipientsByCampaignTypeSelect) {
    //   error("Please select the campaign type  ");
    // }
    else if (status !== "Draft" && !getSelectedCountData?.totalCount) {
      error("Please select the recipients");
    }
    else if (!data.campaignName) {
      error("Please select the campaign name");
    }
    else if (data) {
      const request = {
        EmailCampaignId: 0,
        CampaignName: data.campaignName,
        Subject: data.subject,
        IncludeEmail: data.includeEmail || null,
        ExcludeEmail: data.excludeEmail || null,
        Message: data.message,
        Recipients: getCommaSepratedRecipients(data) || null,
        Attachment: uploadedAttachment || null,
        SendNow: data.send === "SendNow",
        SendLater: data.send === "SendLater",
        SendDate: data.send === "SendNow" ? moment().format('YYYY-MM-DD') : data.sendDate,
        SendTime: data.send === "SendNow" ? moment().add(3, 'minutes').format('HH:mm') : data.sendTime,
        RecipientsByGender: safeJoin(data.recipientsByGenderSelect),
        RecipientsByJobTitle: safeJoin(data.recipientsByJobTitleSelect),
        RecipientsByNationality: safeJoin(data.recipientsByNationalitySelect),
        RecipientsByIndustry: safeJoin(data.recipientsByIndustrySelect),
        RecipientsByTagging: safeJoin(data.recipientsByTaggingSelect),
        RecipientsByStatus: data.recipientsByStatusSelect?.join(', '),
        RecipientsByRace: data.recipientsByRaceSelect?.join(', '),
        RecipientsByResidenceStatus: data.recipientsByResidenceStatusSelect?.join(', '),
        RecipientsByCampaignType: data?.recipientsByCampaignTypeSelect?.value || data?.recipientsByCampaignTypeSelect,
        Summary: "",
        CC: "",
        Status: status,
        AppId: 114,
      };
      addEditEmailCampaign(request);
    } else {
      error("Please enter the campaign name");
    }
  }
  const onFormDataChange = (changedData) => {
    forInitials = { ...changedData }
  }
  const onBlurField = (fieldName, changedData) => {

    if (fieldName === "includeEmail" || fieldName === "excludeEmail") {
      getSelectedEmailCount(changedData)
    }
  }
  const handleFileDownload = async (fileName, fieldName) => {
    if (fieldName === "attachment") {
      const request = {
        type: "CampaignAttachment",
        fileName: uploadedAttachment,
      }

      let getExtension = request.fileName.split('.').pop().toLowerCase();

      // const response = await getPDF(request);

      // saveAs(response?.data?.fileData, response?.data?.fileName);

      if (getExtension === "jpg" || getExtension === "png") {
        const response = await getImage(request);
        if (response?.data?.fileData && response?.originalArgs?.fileName) {
          saveAs(response.data.fileData, response.originalArgs.fileName);
        }
      }
      else {
        const response = await getPDF(request);
        if (response?.data?.fileData && response?.data?.fileName) {
          saveAs(response.data.fileData, response.data.fileName);
        }
      }
    }
  }

  const handleClear = (a, d) => {
    setuploadedAttachment(null);
  };

  const onActionChange = {
    DOWNLOAD: handleFileDownload,
    CLEAR: handleClear
  }

  const handleBackPage = () => {
    navigate("/EmailManagement");
  };
  return (
    <div className="AddEdiCampaignForm-sec">
      <div className="page-title">
        <h2>Content</h2>
      </div>
      <div className="form-section mt-2">
        <div className="row align-items-end">
          <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
            <div className="row">
              <FormCreator
                ref={emailFromRef}
                {...campaignFormData}
                onFormFieldValueChange={onFormFieldValueChange}
                onFormDataChange={onFormDataChange}
                onBlurField={onBlurField}
                onActionChange={onActionChange}
              ></FormCreator>
            </div>
            <div className="row mt-4 mb-3">
              <div className="col-12">
                <div className="big-note-txt">
                  You have selected {getSelectedCountData?.totalCount
                    ? getSelectedCountData?.totalCount
                    : '00'} email addresses for this campaign
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-11 col-xl-11 col-md-11 col-12 mt-5 mb-3">
            <div className="form-button-sec">
              <div className="left-btn">
                <Buttons
                  buttonTypeClassName="back-btn"
                  buttonText="Back"
                  onClick={handleBackPage}
                // isLoading={loginLoading}
                />
              </div>
              <div className="right-btn">
                {addEditEmailCampaignLoading ?
                  <> <Buttons
                    buttonTypeClassName="outline-btn"
                    buttonText="Save as Draft"
                    isDisable={campaignFormData.initialState.send === "SendNow" ? true : false}
                    isLoading={addEditEmailCampaignLoading}
                  // onClick={() => onSubmit('Draft')}
                  />
                    <Buttons
                      buttonTypeClassName="theme-btn"
                      buttonText="Submit"
                      // isDisable={isDisable}
                      isLoading={addEditEmailCampaignLoading}
                    // onClick={() => onSubmit('Sent')}
                    />
                  </>
                  :
                  <>
                    <Buttons
                      buttonTypeClassName="outline-btn"
                      buttonText="Save as Draft"
                      isDisable={campaignFormData.initialState.send === "SendNow" ? true : false}
                      isLoading={addEditEmailCampaignLoading}
                      onClick={() => onSubmit('Draft')}
                    />
                    <Buttons
                      buttonTypeClassName="theme-btn"
                      buttonText="Submit"
                      // isDisable={isDisable}
                      isLoading={addEditEmailCampaignLoading}
                      onClick={() => onSubmit('Sent')}
                    />
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddEdiCampaignForm;